html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
}

.ms-Fabric {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.App {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-login {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 0.4rem;
  background: #ffffff;
}

.App-login-button-container,
.App-login-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-login-image {
  width: 15rem;
  height: 15rem;
}

.App-login-button {
  width: 8rem;
  height: 2.3rem;
  margin-bottom: 0.5rem;
}

.App-login-button-image {
  width: 1rem;
  height: 1rem;
  margin-right: 0.2rem;
}

.App-slack-link,
.App-slack-img {
  width: 8rem;
  height: 2.3rem;
}

.App-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
}

.App-header .App-header-title {
  flex: 1 1 auto;
}

.App-content {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 20rem;
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 0.4rem;
  background: #ffffff;
}

.App-error {
  border: 1px solid gray;
  background-color: #ffffff;
  color: darkred;
  padding: 2rem;
}

.Tasks {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.Tasks-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.Tasks-add {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
}

.Tasks-add-textfield {
  flex: 1 1 auto;
}

.Tasks-list .Task-listitem {
  border: 1px solid;
  border-radius: 0.4rem;
  padding: 0 0.6rem;
  margin: 0.4rem 0;
  display: flex;
  flex-direction: row;
}

.Tasks-list .Task-listitem .Task-checkbox {
  display: flex;
  flex: 0 1 auto;
}

.Tasks-list .Task-listitem .Task-title {
  display: inline-flex;
  flex: 1 1 auto;
  margin: 0 0.4rem;
  overflow: hidden;
  align-items: center;
}

.Tasks-list .Task-listitem .Task-title:hover {
  cursor: pointer;
}

.Tasks-list .Task-listitem .Task-title div {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Tasks-list .Task-listitem .Task-star-link {
  flex: 0 0 auto;
  justify-content: flex-end;
}

.Tasks-list .Task-listitem .Task-conversation-link {
  padding-left: 0.5rem;
}

.dialog-content {
  min-height: 400px;
}

.task-field {
  margin-bottom: 20px;
}

.ms-Persona-initials {
  padding-top: 2px;
  height: 46px;
  width: 48px;
}

.ms-Persona-details {
  padding-top: 5px;
}

.ms-ContextualMenu-Callout {
  background: white;
}

.back-link {
  margin-bottom: 20px;
}
