/* You can add global styles to this file, and also import other style files */
* {
  font-family: Arial;
}

h2 {
  color: #444;
  font-weight: lighter;
}

body {
  margin: 2em;
}

body,
input[text],
button {
  color: #888;
}

div {
  margin: 0.1em;
}

label {
  display: inline-block;
  margin: 0.5em 0;
  color: #888;
}
label.value {
  margin-left: 10px;
  font-size: 14px;
}

input {
  height: 2em;
  font-size: 1em;
  padding-left: 0.4em;
}
input::placeholder {
  color: lightgray;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 3px;
}
